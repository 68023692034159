//mypreset.ts
import { definePreset } from "@primeng/themes";
import Aura from "@primeng/themes/aura";

const clientHubPreset = definePreset(Aura, {
  semantic: {
    // focusRing: {
    //   width: '2px',
    //   style: 'dashed',
    //   color: '{primary.color}',
    //   offset: '1px'
    // },
    colorScheme: {
      light: {
        blue: {
          0: "#FFFFFF",
          50: "#E3F5FF",
          100: "#CCECFF",
          200: "#99D5FF",
          300: "#66BAFF",
          400: "#3FA0FF",
          500: "#0075FF",
          600: "#005ADB",
          700: "#0043B7",
          800: "#002F93",
          900: "#00217A",
          950: "#001E6F",
        },
      },
    },
    primary: {
      50: "{blue.50}",
      100: "{blue.100}",
      200: "{blue.200}",
      300: "{blue.300}",
      400: "{blue.400}",
      500: "{blue.500}",
      600: "{blue.600}",
      700: "{blue.700}",
      800: "{blue.800}",
      900: "{blue.900}",
      950: "{blue.950}",
    },
  },
});

export default clientHubPreset;
