import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fromStorage } from "../from-storage.function";

@Component({
  selector: "app-close-qb-oauth",
  templateUrl: "./close-qb-oauth.component.html",
  styleUrls: ["./close-qb-oauth.component.scss"],
})
export class CloseQbOauthComponent implements OnInit {
  route = inject(ActivatedRoute);
  readonly newCompanyConnectionStatus = fromStorage<boolean>(
    "newCompanyConnectionStatus",
  );
  constructor() {}

  ngOnInit(): void {
    const status = this.route.snapshot.queryParamMap.get("status");
    console.log("Status", status);

    this.newCompanyConnectionStatus.set(
      this.route.snapshot.queryParamMap.get("status") === "success",
    );

    window.close();
  }
}
