import {
  ApplicationRef,
  ErrorHandler,
  isDevMode,
  importProvidersFrom,
  provideZoneChangeDetection,
  inject,
  provideAppInitializer,
  APP_INITIALIZER,
} from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { NgxTurnstileModule } from "ngx-turnstile";
import {
  enableDebugTools,
  BrowserModule,
  bootstrapApplication,
} from "@angular/platform-browser";
import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";

import { AppComponent } from "./app/app.component";
import {
  ServiceWorkerModule,
  provideServiceWorker,
} from "@angular/service-worker";
import { HotToastModule } from "@ngneat/hot-toast";
import { provideDatabase, getDatabase } from "@angular/fire/database";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { environment as environment_1 } from "./environments/environment.prod";
import { provideFirebaseApp, initializeApp, getApp } from "@angular/fire/app";
import { CodeInputModule } from "angular-code-input";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";

import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import {
  provideFirestore,
  getFirestore,
  initializeFirestore,
} from "@angular/fire/firestore";
import { NgxIntlTelInputModule } from "@justin-s/ngx-intl-tel-input";
import { RegistrationModule } from "./app/registration/registration.module";
import { AppRoutingModule } from "./app/app-routing.module";
import { Router, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { providePrimeNG } from "primeng/config";
import { LoadChunkErrorHandler } from "./app/utils/chunk.error.handler";
import { AuthInterceptor } from "./app/interceptors/auth.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  LocationStrategy,
  HashLocationStrategy,
  CommonModule,
} from "@angular/common";
import { MyAccountService } from "./app/dashboard/main/common/modals/my-account-modal.service";
import { ClientGuard } from "./app/guards/client.guard";
import { PendingChangesGuard } from "./app/guards/component-can-deactivate.guard";
import { AuthGuard } from "./app/guards/auth.guard";
import { ChatService } from "./app/dashboard/chat.service";
import { DashboardService } from "./app/dashboard/dashboard.service";
import { LoginService } from "./app/login/login.service";
import * as Sentry from "@sentry/angular";
import { STORAGE } from "./app/storage.service";
import Lara from "@primeng/themes/lara";
import clientHubPreset from "src/clientHubPreset";

import posthog from "posthog-js";

Sentry.init({
  dsn: "https://25265704b00a849cfb1d3334f8fe6268@o4507387170390016.ingest.us.sentry.io/4507387270201344",
  environment: environment.envName,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

posthog.init(environment.posthog.key, {
  api_host: environment.posthog.host,
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

if (environment.production) {
  enableProdMode();

  if (window) {
    window.console.log = function () {};
    window.console.warn = function () {};
  }
}

// registerLicense(
//   "ORg4AjUWIQA/Gnt2UVhhQlVCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5RdEBiXX9fcHdWQWJV"
// );

// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// if (environment.hmr) {
//   if (module["hot"]) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     console.error("HMR is not enabled for webpack-dev-server!");
//     console.log("Are you using the --hmr flag for ng serve?");
//   }
// } else {
//   bootstrap().catch((err) => console.log(err));
// }

(window as any).__zone_symbol__UNHANDLED_PROMISE_REJECTION_HANDLER = function (
  error: any,
) {
  const chunkFailedPatterns = [
    /Loading chunk [\d]+ failed/,
    /Loading CSS chunk [\d]+ failed/,
    /Failed to fetch dynamically imported module/,
    /ChunkLoadError/,
  ];

  if (chunkFailedPatterns.some((pattern) => pattern.test(error?.message))) {
    console.warn("Chunk load failed. Reloading page...");
    window.location.reload();
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      FormsModule,
      RouterModule,
      AppRoutingModule,
      RegistrationModule,
      NgxIntlTelInputModule,

      ModalModule.forRoot(),
      BsDropdownModule.forRoot(),
      TooltipModule.forRoot(),
      TypeaheadModule.forRoot(),
      IntercomModule.forRoot({
        appId: "wqv1cgow",
        updateOnRouterChange: true,
      }),
      CarouselModule.forRoot(),
      AccordionModule.forRoot(),
      TabsModule.forRoot(),
      CodeInputModule.forRoot({
        codeLength: 6,
      }),
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFirestore(() => {
        const app = getApp();
        const dbName = environment.firebaseConfig.dbName;
        const providedFirestore = initializeFirestore(app, {}, dbName);
        return providedFirestore;
      }),
      // provideDatabase(() => getDatabase(getApp(), "firestore-qa")),
      HotToastModule.forRoot({
        style: {
          "z-index": 99999,
          padding: "16px",
        },
      }),

      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: "registerWhenStable:30000",
      }),
    ),
    LoginService,
    DashboardService,
    ChatService,
    AuthGuard,
    PendingChangesGuard,
    ClientGuard,
    NgxTurnstileModule,
    MyAccountService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: "googleTagManagerId",
      useValue: "GTM-WZ2RRT4",
    },
    { provide: ErrorHandler, useClass: LoadChunkErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    [{ provide: STORAGE, useValue: localStorage }],
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: clientHubPreset,
        options: {
          darkModeSelector: false || "none",
        },
      },
    }),
    provideServiceWorker("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
})
  .then((moduleRef) => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];

    // allows to run `ng.profiler.timeChangeDetection();`
    // enableDebugTools(componentRef);
  })
  .catch((err) => window["console"].error(err));
